<template>
  <div
    class="pa-0 ma-0 white-wavy-wallpaper"
    style="width: 100%; height: 100%; overflow:hidden;"
  >
    <app-bar
      class="white-wavy-wallpaper"
      :drawer="drawer"
      @toggleSideBar="toggle"
    ></app-bar>
    <side-bar
      :items="items"
      :avatar="avatar"
      :drawer="drawer"
      :jobTitle="jobTitle"
      :exactLink="exactLink"
      :highlightTitle="highlightTitle"
      @logoutEvent="logoutDialog = true"
    />
    <div>
      <v-container class="pl-12 ml-12 pt-6">
        <admin-header
          class="mb-12"
          v-if="!skipBreadcrumb"
          :title="headerTitle"
          :links="links"
        ></admin-header>
        <router-view />
      </v-container>
    </div>
    <v-dialog v-model="logoutDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="subtitle-1"
          >Are you sure you want to leave?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="buttons" :loading="logoutLoading" text @click="onLogout"
            >Yes</v-btn
          >
          <v-btn color="buttons" text @click="logoutDialog = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="end" class="mr-12 mb-12 pb-12">
      <bz-support email="customersupport@beezsocialerp.com"></bz-support>
    </v-row>
  </div>
</template>

<script>
import BzSupport from "@shared/components/BzSupport";

import AppBar from "@/beez-admin/components/AppBar";
import SideBar from "@/beez-admin/components/SideBar";
import AdminHeader from "@/beez-admin/components/AdminHeader";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { roles } from "@/shared/constants/roles";

export default {
  components: {
    SideBar,
    AppBar,
    AdminHeader,
    BzSupport,
  },
  computed: {
    ...mapFields("auth", ["currentUser", "accountRoles"]),
    items() {
      if (this.accountRoles.includes(roles.ACCOUNT_OWNER)) {
        return [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            link: "account-dashboard",
            type: "item",
          },
          {
            title: "Elevation Module",
            icon: "mdi-elevation-rise",
            link: "evaluator-home",
            type: "item",
          },
        ];
      } else {
        return [
          {
            title: "Elevation Module",
            icon: "mdi-elevation-rise",
            link: "evaluator-home",
            type: "item",
          },
        ];
      }
    },
  },
  methods: {
    ...mapActions("auth", {
      logoutUser: "logoutUser",
    }),
    ...mapActions("evaluator", {
      getEvaluatorTraining: "getEvaluatorTraining",
    }),
    toggle() {
      this.drawer = !this.drawer;
    },
    async onLogout() {
      this.logoutLoading = true;
      try {
        await this.logoutUser();
        this.logoutDialog = false;
        this.logoutLoading = false;
      } finally {
        this.$router.replace({
          name: "log-in",
        });
      }
    },
    async fetchEvaluatorTraining() {
      try {
        await this.getEvaluatorTraining();
      } catch (error) {}
    },
    async loadInitialData() {
      await Promise.all([this.fetchEvaluatorTraining()]);
    },
  },
  async created() {
    await this.loadInitialData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(value) {
        if ("skip" in value.meta.breadcrumbs && value.meta.breadcrumbs.skip) {
          this.skipBreadcrumb = true;
        } else {
          this.skipBreadcrumb = false;
          this.links = value.meta.breadcrumbs;
          if (this.links && this.links.length > 0) {
            this.headerTitle = this.links[this.links.length - 1].text;
          } else {
            this.headerTitle = "";
          }
        }

        const path = value.path.split("/");
        if (path.length > 2) {
          this.exactLink = false;
          this.highlightTitle = this.items[this.items.length - 1].title;
        } else {
          this.exactLink = true;
        }
      },
    },
  },
  data() {
    return {
      avatar: "",
      jobTitle: "",
      drawer: false,
      links: [],
      headerTitle: "",
      skipBreadcrumb: false,
      highlightTitle: "",
      exactLink: true,
      logoutDialog: false,
      logoutLoading: false,
      fetchedLogoImage: null,
      accountName: null,
    };
  },
};
</script>
